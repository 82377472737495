import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms';
import { ContactUsService } from '../contactUsService/contact-us.service';
import { Contact } from '../contact_class/contact';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})


export class ContactComponent implements OnInit {
   ngOnInit(): void {}

   topics=['Angular', 'React', 'vue'];

   userModel = new Contact('Rob', 'rob@test.com', 9794022170, 'kfloTech', true)
   submitted = false
   constructor(private _contactUsService: ContactUsService){}
 
 
   topicHasError=true;
   validateTopic(value){
     if(value === 'default'){
       this.topicHasError=true;
     }else{
       this.topicHasError = false;
     }
   }
 
   onSubmit(){
     this.submitted = true;
     console.log(this.userModel)
     this._contactUsService.contact_us_form(this.userModel)
     .subscribe(
       data=> console.log('Successs!', data),
       error => console.error('Error!', error)
     )
   }
 
}