<div class="container-fluid" style="margin-top:30px; margin-bottom: 30px;">
  <!-- <div class="row justify-content-center align-items-center">
    
    <div class="form-box col-sm-6 row justify-content-center align-items-center ">
      <h2>Contact Us!</h2>
      <form [formGroup]="services_inquiry_form" class="p-3" (ngSubmit)="onSubmit()">

        <div class="form-group">
          <div class="d-inline-flex m-3">
            <label class="m-2"> Your Name:
            <br>
            <input type="text" formControlName="contact_name" name="fname">
            </label>
            <br>

            <label class="m-2"> Company Name:
              <br>
              <input type="text" formControlName="company_name" name="fname">
            </label>
            <br>
  
        </div>
        <br>
        <div class="d-inline-flex m-3">


          <label class="p-1 "> Company Email:
            <br>
            <input type="email" formControlName="company_email" name="fname">
          </label>
          <br>

          <label class="p-1 "> Contact Number:
            <br>
            <input type="text" formControlName="company_phone_number" name="fname">
          </label>
          <br>
        </div>

          <h3 > Requested Services:</h3>
          <br>


          
          <div formGroupName="offered_services" class="grouping" id="services_css">
            <div class="sibling-fade">

            
            <div class="d-inline-flex m-1">
              <p >
                <label><input formControlName="patrol_consultations" type="checkbox"  ><span>Patrol Service</span></label>
                <label><input formControlName="special_events" type="checkbox" ><span>Special Events</span></label>
                <label><input formControlName="dedicated_guard" type="checkbox" ><span>Dedicated Guard</span></label>

              </p>
            </div>
            <br>
            <div class="d-inline-flex m-1" >
              <p >
                <label><input formControlName="controlled_access" type="checkbox" ><span>Controlled Access</span></label>
                <label><input formControlName="alarm_response" type="checkbox" ><span>Alarm Response</span></label>
                <label><input formControlName="seasonal_service" type="checkbox" ><span>Seasonal Service</span></label>
                <br>
              </p>
            </div>

            <br>
            <div class="d-inline-flex m-1">
              <p>
                <label><input formControlName="threat_loss_mitigation" type="checkbox" ><span>Fire Watch</span></label>
                <label><input formControlName="virtual_surveillance" type="checkbox" ><span>Virtual Surveillance</span></label>
                <label><input  formControlName="randomized_vehicle_patrols" type="checkbox" ><span>Secuirty Consult</span></label>
    
                <br>
              </p>
            </div>
          </div>
          </div> 
          <br>

          <label class="p-1 "> Contact Message:
            <br>
            
            <textarea type="textarea" formControlName="contact_message"   rows="10" cols="50"></textarea>
          </label>
          <br>

          <button id="Send_button">Send Email</button>
        </div>
      </form>
    </div>

  </div> -->
  
  
  <div class="container-fluid">
    <h1> Bootcamp enroll</h1>
    <form #userForm="ngForm" *ngIf="!submitted" (ngSubmit)="onSubmit()" novalidate>
  
  
  
      <div clas="form-group">
        <label>Name</label>
        <input type="text" required #name="ngModel" 
          [class.is-invalid]="name.invalid && name.touched"  
          class="form-control is-invalid" 
          name="userName" [(ngModel)]="userModel.name">
          <small class="text-danger" [class.d-none]="name.valid || name.untouched">Name is required</small>
      </div>
      <div class="form-group">
        <label>Email</label>
        <input type="email" class="form-control" name="email" [(ngModel)]="userModel.email">
      </div>
  
      <div class="form-group">
        <label>Phone</label>
        <input type="tel" #phone="ngModel" pattern="^\d{10}$" required
          [class.is-invalid]="phone.invalid && phone.touched" class="form-control" name="phone"   
          [(ngModel)]="userModel.phone">
          <!-- <small class="text-danger" [class.d-none]="phone.valid || phone.untouched">Phone must be 10 numbers and required</small> -->
          <div *ngIf="phone.errors && (phone.invalid || phone.touched)">
            <small class="text-danger" *ngIf="phone.errors.required">Phone number is required</small>
            <small class="text-danger" *ngIf="phone.errors.pattern">Phone number must be 10 digits</small>
  
          </div>
        </div>
  
      <!-- <div class="form-group">
        <select (blur)="validateTopic(topic.value)" (change)="validateTopic(topic.value)" 
        #topic="ngModel" 
        [class.is-invalid]="topicHasError && topic.touched" class="custom-select" name="topic"   
        [(ngModel)]="userModel.topic">
          <option value="default">I am interested in</option>
          <option *ngFor="let topic of topics">{{topic}}</option>
        </select>
        <small class="text-danger" [class.d-none]="!topicHasError || topic.untouched">Please choose topic</small>
      </div> -->
  
      <!-- <div class="mb-3">
        <label>Time Preference</label>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="timePreference" value="morning" [(ngModel)]="userModel.timePreference">
          <label class="form-check-label">Morning (9am-12pm)</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="timePreference" value="evening" [(ngModel)]="userModel.timePreference">
          <label class="form-check-label">Evening (5pm-8pm)</label>
        </div>
      </div>
  
      <div class="form-check mb-3">
        <input class="form-check-input"  name="subscribe" type="checkbox" [(ngModel)]="userModel.subscribe">
        <label class="form-check-label">
          Send promo deals
        </label>
      </div> -->
  
      <!-- <button [disabled]="userForm.form.invalid || topicHasError" class="btn btn-primary" type="submit">Submit Form</button> -->
      <button  class="btn btn-primary" type="submit">Submit Form</button>

    </form>

  </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  <!-- <p>User Settings</p>
  <form #form="ngForm" novalidate (ngSubmit)="onSubmit(form)">

    <div class="form-group">
      <label for="name">Name</label>
      <input id="name" name="name" class="form-control" [(ngModel)]="userSettings.name" 
      required #nameField="ngModel"
      (blur)="onBlur(nameField)"
      [class.field-error]="form.submitted && nameField.invalid">
      <div [hidden]="!form.submitted || nameField.valid"
      class="alert alert-danger">
      Enter a Name
    </div>

    </div>
     <h3>dirty: {{propertyTest.dirty}}</h3>
    <h3>pristine: {{propertyTest.pristine}}</h3>
    <h3>touched: {{propertyTest.touched}}</h3>
    <h3>untouched: {{propertyTest.untouched}}</h3>
    <h3>valid: {{propertyTest.valid}}</h3>
    <h3>invalid: {{propertyTest.invalid}}</h3> -->
<!-- 
    <div class="form-check form-group">
      <input class="form-check-input" type="checkbox"
      id="emailOffers" name="emailOffers" [(ngModel)]="userSettings.emailOffers">
      <label class="form-check-label" for="emailOffers">
        Email Special Offers
      </label>
    </div>

    <h5>User interface styles</h5>
    <div class="form-group">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="interfaceStyle"
        id="lightInterface" value="light" checked [(ngModel)]="userSettings.interfaceStyle">
        <label class="form-check-label" for="lightInterface">
          light
        </label>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="radio" name="interfaceStyle"
        id="mediumInterface" value="medium" [(ngModel)]="userSettings.interfaceStyle">
        <label class="form-check-label" for="mediumInterface">
          Medium
        </label>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="radio" name="interfaceStyle"
        id="darkInterface" value="light" [(ngModel)]="userSettings.interfaceStyle">
        <label class="form-check-label" for="darkInterface">
          dark
        </label>
      </div>
    </div>


    <div class="form-group">
      <label for="subscriptionType">subscription Type</label> 
      <select class="form-control" id="subscriptionType" [(ngModel)]="userSettings.subscriptionType">
        <option *ngFor="let type of subscriptionType | async">{{type}}</option>

      </select>
    </div>

    <div class="form-group">
      <label for="notes">Notes</label>
      <textarea id="notes" name="notes" class="form-control"
      rows="3" [(ngModel)]="userSettings.notes"></textarea>
    </div>

    <div [hidden]="!postError"
    class="alert alert-danger">
    {{postErrorMessage}}
  </div>

    <button class="btn btn-primary">Send</button>

  </form> 
</div> -->

<!-- {{ form.ngSubmit | json }}
{{ form.form.value | json }}
{{ form.statusChanges | json }} -->


