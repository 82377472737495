import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Contact } from '../contact_class/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  //local host testing 
  // _url ='http://localhost:3000/contact';
  _url='https://ctxpatrol.com/contact_us_form';
  constructor(private _http: HttpClient) { }

  contact_us_form(contact: Contact){
  // contact(contact1: Contact){

    // return this._http.post<any>(this._url, contact)
    return this._http.post(this._url, contact)

  }
}
