<div class="row justify-content-center">
        <div class="col-md-10 text-center ">
                <h2>Employment At CTX!</h2>
                <h4>Job Title: Community Base Roving Patrol-Vehicle Patrol Duty </h4>
        </div>
</div>
<div class="row justify-content-center">
        <div class="col-md-6 text-left">
                <p>Location: Austin Texas</p>
        </div>
        <div class="col-md-4 text-right">
                <p>Reports to: Lead Officer/ Field Supervisor </p>
        </div>
</div>
<div class="row justify-content-center">
        <div class="col-md-6 text-left">
                <p>Email application to: ctxpatrolservice@gmail.com</p>
        </div>
        <div class="col-md-4 text-right">
                <p>Download application:  <a href="../assets/emp_app.pdf">CTX Application</a></p>
        </div>
</div>

<div class="row justify-content-center ">
        <div class="col-md-10 text-right">
                <span>An CTX Community Based Officer (CBS) is an officer that does Patrol Duty is performed in a CTX
                        Patrol Service Security vehicle.
                        This vehicle is fully equipped with graphics, A GPS mapping device, a Laptop computer, a mobile
                        phone and a light bar and go light.
                        The Vehicle Patrol Duty is a demanding and high priority position. The officers working should
                        be thorough efficient and highly trained.
                        The officers must maintain the schedule and also address any security needs as they arise.
                </span>
        </div>
</div>
<div class="row justify-content-center">
        <div class="col-md-5 text-left"><br>
                <h4>
                        Responsibilities
                </h4>
                <ul>
                        <li>This includes parking lots and rear of the property at a speed of approximately 5-10mph.
                        </li>
                        <li>The officer will patrol through the entire</li>
                        <li>Vehicle windows down.</li>
                        <li>Radio down to hear outside noise or parties.</li>
                        <li>Light Bar lights flashing front and rear with the side lights on to help see damage.</li>
                        <li>Go-Light on and in use shining throughout the property at suspicious locations.</li>
                        <li>Eyes looking at various locations where there may be potential for crime.</li>
                        <li>Hazard Lights flashing throughout patrol.</li>
                        <li>Officer should be attentive and off of the phone and any other distractions.</li>
                </ul>
        </div>
        <div class="col-md-5 text-right"><br>
                <h4>
                        Requirements
                </h4>
                <ul>
                        <li>18 years of age or older
                        </li>
                        <li>High school diploma or GED</li>
                        <li>Pass background investigation</li>
                        <li>Pass drug test</li>
                        <li>Good driving record</li>
                        <li>Complete training modules within first month</li>
                        <li>Excellent organization, oral, and written communication skills </li>
                        <li>Ability to conform to established procedures and to help identify and solve problems</li>
                        <li>Works collaboratively and supports efforts of team members</li>
                        <li>Ability to conform to established procedures and to help identify and solve problems</li>
                        <li>Ability to get along with other employees, follow directions, work under stress, add value and continuously improve</li>
                        <li>Demonstrates a commitment to service, organization values and professionalism through appropriate conduct and demeanor at all times</li>
                        <li>Must be able to move equipment weighing up to 50 pounds</li>

                </ul>
        </div>
</div>
<div class="row  justify-content-center">

        <div class="col-md-10 text-right">
                
        </div>
</div>
<div class="row justify-content-center">

</div>
<div class="row  justify-content-center">

        <div class="col-md-10 text-right">
                
        </div>
</div>