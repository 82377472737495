import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AboutComponent } from './unsecuredPages/about/about/about.component';
import { WelcomeComponent } from './unsecuredPages/home/welcome/welcome.component';
import { ContactComponent } from './unsecuredPages/contact/contact.component';

import { ReactiveFormsModule } from '@angular/forms';
import { ServicesComponent } from './unsecuredPages/services/services.component';
import { EmploymentComponent } from './unsecuredPages/employment/employment.component';
import {HttpClientModule} from '@angular/common/http'
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
// import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    WelcomeComponent,
    ContactComponent,
    ServicesComponent,
    EmploymentComponent,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    
  ],
  providers: [
  ],
  bootstrap: [AppComponent,]
})
export class AppModule { }
