<div class="container-fluid" style="margin-top:30px">
  <div class="row justify-content-center align-items-center">


    <div class="col-md-9 text-center">
      <h2>The CTX Patrol Mission</h2>
      <p>
        Our company provides Patrol Services for Commercial and Residential properties.
        Any asset that a property owner wants to keep safe and secure is what we are here for.
        Giving our clients Peace of Mind knowing we are there when they are not. Patrol Service
        presence is a great deterrent and way to keep your property the way you left it.
      </p>
    </div>
  </div>
  <br>



  <div class="row sibling-fade">

    <div class="col-md-4 box-wrap">
      <a href="/services">
        <div class="card d-flex bg-dark box">
          <img class="card-img" src="../assets/images/contentImages/contact_us.jpg" alt="services thumbnail">
          <div class="card-img-overlay align-items-center d-flex justify-content-center">
            <h5 class="card-title thumbnail-info">Services</h5>

          </div>

        </div>
      </a>
    </div>


    <div class="col-md-4 box-wrap">
      <a href="#">
        <!-- <a href="contact"> -->

        <div class="card bg-dark box">
          <img class="card-img" src="../assets/images/contentImages/services.jpg" alt="services thumbnail">
          <div class="card-img-overlay align-items-center d-flex justify-content-center">
            <h5 class="card-title thumbnail-info">Contact Us!</h5>

          </div>
        </div>
      </a>
    </div>


    <div class="col-md-4 box-wrap">
      <a href="/employment">
        <div class="card bg-dark box">
          <img class="card-img" src="../assets/images/contentImages/employment.jpg" alt="services thumbnail">
          <div class="card-img-overlay align-items-center d-flex justify-content-center">
            <h5 class="card-title thumbnail-info">Employment</h5>

          </div>
        </div>
      </a>
    </div>
  </div>


  <br>
  <div class="row justify-content-center ">
    <div class="col-md-9 text-center">
      <h2> About CTX Patrol</h2>
      <p>
        CTX Patrol Services strives to achieve these simple but very important goals.
        Integrity, Dependability, Communication, Customer Commitment, and Team Work.
        We believe that nothing is more important than our reputation,
        and behaving with the highest levels of integrity is fundamental to who we are.
        We demonstrate a strong commitment to sustainable and responsible business practices.
        Taking pride in our ability to be there when you're not and making sure you and your property
        are taken care of is what we do. Dependability is sometimes taken for granted we make it our mission.
        Communication is key. Making sure we are professionally reporting our findings and observations to our
        clients is what will give them the peace of mind they deserve. We are committed to our clients. Your property
        is
        our property and we take great pride in treating it that way. Our company is a team that works together to
        provide the
        best service in the industry.
        Be part of our winning team.
      </p>
      <br>
    </div>
  </div>
</div>











<!--PLACE HOLDER FOR CENTRAL IMAGES-->
<!-- 
  <div class="row justify-content-center">
    
      <div class="col-md-4 thumbnails-div">
        <div class="container">

        <div class="thumbnail-card">
          <a href="contact-component">
            <img class="img img-responsive"
              href="contact-component" src="../assets/images/contentImages/contact_us.jpg" alt="contact thumbnail">
          </a>
          <div class="card-info">Contact Us!</div>
        </div>
      </div>

    </div> -->

<!--style="max-width: 300px;  max-height: 200px;  border-radius: 5%;"-->
<!-- 
    <div class="col-md-4 thumbnails-div">
      
      <div class="thumbnail-card">

        <a href="services-component">
          <img  class="img img-responsive"
            href="contact-component" src="../assets/images/contentImages/services.jpg" alt="services thumbnail">
        </a>
        <div class="card-info">Services!</div>

      </div>
    </div>

    <div class="col-md-4 thumbnails-div">
      <div class="thumbnail-card">

        <a href="employment-component">
          <img class="img img-responsive"
            href="contact-component" src="../assets/images/contentImages/employment.jpg" alt="employment thumbnail">
        </a>
        <div class="card-info">Services!</div>

      </div>
    </div>
  </div> -->