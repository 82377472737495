import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './unsecuredPages/about/about/about.component';
import { ContactComponent } from './unsecuredPages/contact/contact.component';
import { EmploymentComponent } from './unsecuredPages/employment/employment.component';
import { WelcomeComponent } from './unsecuredPages/home/welcome/welcome.component';
import { ServicesComponent } from './unsecuredPages/services/services.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const routes: Routes = [
  { path: '', component: WelcomeComponent},
  { path: 'home', component: WelcomeComponent},

  { path: 'about', component: AboutComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'services', component: ServicesComponent},
  { path: 'employment', component: EmploymentComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  // imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
