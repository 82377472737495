<!----------------------begin navbar------------------>

<div class="container-fluid px-0">
  <nav class="navbar navbar-expand-sm navbar-dark  sticky-top bg-custom " style="background-color: rgb(54, 53, 53);">

    <div class="container-fluid justify-content-between">
      <!-- left elements-->
      <div class="d-flex">
        <a class="navbar-brand me-2 mb-1 d-flex align-items-center" href="#">
          <picture class="justify-content-">
            <source srcset="../assets/images/logos/ctxLogoLight.png"
              media="(prefers-color-scheme: dark), (-ms-high-contrast: active)">
            <img class="nav-logo" src="../assets/images/logos/ctxLogo.png">
          </picture>
        </a>
      </div>

      <!-- center elements-->
      <ul class="navbar-nav justify-content-center">

        <li class="nav-item me-3 me-lg-1 active "> <a class="nav-link" routerLinkActive='active'
            routerLink='/'>Home</a> </li>
            
        <!-- <li class="nav-item me-3 me-lg-1"> <a class="nav-link" href="/">About</a> </li> -->
        <!-- <li class="nav-item me-3 me-lg-1 "> <a class="nav-link" href="/hrms#/services">Services</a> </li>
        <li class="nav-item me-3 me-lg-1"> <a class="nav-link" href="/hrms#">Contact</a> </li> -->
        <li class="nav-item me-3 me-lg-1 "> <a class="nav-link" href="/services">Services</a> </li>
        <li class="nav-item me-3 me-lg-1"> <a class="nav-link" href="/">Contact</a> </li>
        <!--         <li class="nav-item me-3 me-lg-1"> <a class="nav-link" href="contact">Contact</a> </li>
 -->
        
        <li class="nav-item me-3 me-lg-1"> <a class="nav-link" href="/employment">Employment</a> </li>
        <!-- <li class="nav-item me-3 me-lg-1"> <a class="nav-link" href="/hrms#/employment">Employment</a> </li> -->

      </ul>

      <!-- Right elements -->
      <ul class="navbar-nav flex-row right-elm  social-list">
        
        <li class="nav-item me-6 me-lg-2 right-elm-ico  social-item">
          <a href="#" class="icon-insta">
            <i class='fa fa-instagram fa-2x'></i>
          </a>
          <br />
        </li>
        <hr>
        
        <li class="nav-item me-6 me-lg-1 right-elm-ico  social-item ">
          <a href="#" class="icon-fb">
            <i class='fa fa-facebook fa-2x'></i>
          </a>
          <br />
        </li>
        
        <li class="nav-item me-6 me-lg-1 right-elm social-item">
          <a href="#" class="icon-twitter">
            <i class='fa fa-twitter fa-2x'></i>
          </a>
          <br />
        </li>

        <li class="nav-item me-6 me-lg-1 right-elm  social-item">
          <a href="#" class="icon-indeed">
            <i class="fa fa-linkedin fa-2x"></i>
          </a>
          <br />
        </li>
      </ul>

    </div>
  </nav>

  <!----------------------end navbar------------------>



  <!----------------------begin jumbo------------------>

  <div class="jumbotron">
    <img src="../assets/images/header/city-of-austin-skyline.jpg">
  </div>
  <!----------------------end jumbo------------------>



  <div class='container'>
    <router-outlet></router-outlet>
  </div>
</div>

<!----------------------begin footer------------------>
<!--put list in row-->

<footer class="footer-container" style="background-color: rgb(54, 53, 53); color: white;">
  <div class="container main-footer ">
    <aside class="footer-sidebar widget-area row" role="complementary">
      <div class="footer-column footer-column-1 col-12">
        <div id="text-9" class="wd-widget widget footer-img  widget_text">
          <div class="textwidget">
            <div class="footer-logo  mx-auto" style="max-width: 80%; margin-bottom: 10px;">
              <picture>
                <source srcset="../assets/images/logos/ctxLogoLight.png"
                  media="(prefers-color-scheme: dark), (-ms-high-contrast: active), (-ms-high-contrast: active)">
                <img class="footer-img" src="../assets/images/logos/ctxLogo.png" style="margin-bottom: 10px;"
                  width="250px">
              </picture>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-column footer-column-2 col-12 col-sm-6 col-lg-3  mx-auto">
        <div id="block-4" class="wd-widget widget footer-widget  widget_block">
          <h3>INFORMATION</h3>
        </div>
        <div id="text-16" class="wd-widget widget footer-widget  widget_text">
          <div class="textwidget">
            <h4>CTX Patrol</h4>
            <h5>Corporate Office:</h5>
            <div style="line-height: 2;" data-ctm-watch-id="1" data-ctm-tracked="1" data-observe="1"
              data-observer-id="0" data-ctm-remark="1">
              <i class="fa fa-location-arrow" style="width: 15px; text-align: center; margin-right: 4px;"></i>12205 N.
              Lamar Blvd Unit # 1620<br>
              Austin Texas 78753<br>
              <i class="fa fa-mobile" style="width: 15px; text-align: center; margin-right: 4px;"></i> Phone: (512)
              888-1354<br>
              <i class="fa fa-envelope-o" style="width: 15px; text-align: center; margin-right: 4px;"></i> Email:
              ctxpatrolservice@gmail.com
            </div>
          </div>
        </div>
      </div>
      <div class="footer-column footer-column-3 col-12 col-sm-6 col-lg-3  mx-auto">
        <div id="block-5" class="wd-widget widget footer-widget  widget_block">
          <h3>NAVIGATION MENU</h3>
        </div>
        <div id="nav_menu-4" class="wd-widget widget footer-widget  widget_nav_menu">
          <div class="menu-footer-navigation-container">
            <ul id="menu-footer-navigation" class="menu">
              <li id="menu-item-12270"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-1200 current_page_item menu-item-12270">
                <a href="# " aria-current="page">Home</a>
              </li>
              <!-- <li id="menu-item-12271" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12271">
                <a href="/hrms#/">About</a></li> -->
              <li id="menu-item-12273" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12273">
                <a href="/employment">Employment</a></li>
              <li id="menu-item-18785" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-18785">
                <a href="/">Contact</a></li>
              <li id="menu-item-19807"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-19807">
                <a href="/services">Services</a></li>
            </ul>
          </div>
        </div>
        <div id="block-3" class="wd-widget widget footer-widget  widget_block widget_text">

          <p>
          </p>
        </div>
      </div>
      <div class="footer-column footer-column-4 col-12 col-sm-6 col-lg-3  mx-auto">
        <div id="block-6" class="wd-widget widget footer-widget  widget_block">
          <h3>OUR SERVICES</h3>
        </div>
        <div id="nav_menu-2" class="wd-widget widget footer-widget  widget_nav_menu">
          <div class="menu-our-services-container">
            <ul id="menu-our-services" class="menu">
              <li id="menu-item-20480" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20480">
                <a href="/services">Virtual Surveillance</a>
              </li>
              <li id="menu-item-20115" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20115">
                <a href="/services">Special Events</a>
              </li>
              <li id="menu-item-20481" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20481">
                <a href="/services">Seasonal Service</a>
              </li>
              <li id="menu-item-20483" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20483">
                <a href="/services">Controlled Access</a>
              </li>
              <li id="menu-item-20484" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20484">
                <a href="/services">Fire Watch</a>
              </li>
              <!-- <li id="menu-item-20485" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20485">
                <a href="/hrms#/services">Security Consult</a>
              </li>
              <li id="menu-item-20485" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20485">
                <a href="/hrms#/services">Dedicated Guard</a>
              </li>
              <li id="menu-item-20485" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20485">
                <a href="/hrms#/services">Alarm Response</a>
              </li>
              <li id="menu-item-20485" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20485">
                <a href="/hrms#/services">Patrol Services</a>
              </li> -->

            </ul>
          </div>
        </div>
      </div>
    </aside><!-- .footer-sidebar -->
  </div>
  <div class="copyrights-wrapper copyrights-two-columns  mx-auto">
    <div class="container">
      <div class="min-footer">
        <div class="col-left set-cont-mb-s reset-last-child">
          <p>© 2022 <a href="https://ctxpatrol.com/">CTX Patrol - Designed by Kflores</a>. All rights reserved</p>
        </div>
      </div>
    </div>
  </div>
</footer>
<!----------------------end footer------------------>