import { Component, OnInit } from '@angular/core';
import { PaginationControlsComponent } from 'ngx-pagination';
@Component({
  selector: 'app-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.css']
})
export class EmploymentComponent implements OnInit {
  ngOnInit(): void {
  }
  page: number = 1;
  onTableDataChange(event: any) {
    this.page = event;
  }

  collection: any[] = [
    { "service_name": 'Patrol Services' },
    {
      "service_desc": `With CTX Patrol Service LLC. Premium Patrol Service, our uniformed officer arrives on property and conducts a thorough inspection of your property.
      This is not the typical security truck drive-through. We have verified services, and our Patrols may be random or scheduled for a specific time. The number of visits and inspections per shift are customized to the client’s needs. Our Patrol Officer will Patrol the entire property including Checking buildings,  pools, parking areas and any recreational facilities by vehicle. 
      After each patrol of the property, we report all findings in our reports.`}]





  responsibilities: any[] = [
    { "resp_name": "Touring Properties" },
    { "prop_resp": "This includes parking lots and rear of the property at a speed of approximately 5-10mph." },
    { "prop_resp": "Vehicle windows down." },
    { "prop_resp": "Radio down to hear outside noise or parties." },
    { "prop_resp": "Light Bar lights flashing front and rear with the side lights on to help see damage." },
    { "prop_resp": "Go-Light on and in use shining throughout the property at suspicious locations." },
    { "prop_resp": "Eyes looking at various locations where there may be potential for crime." },
    { "prop_resp": "Hazard Lights flashing throughout patrol." },
    { "prop_resp": "Officer should be attentive and off of the phone and any other distractions." },
    { "resp_name": "Report/Record Suspicious Activity" },
    { "prop_resp": "Unauthorized vehicles." },
    { "prop_resp": "Cars that have been broken into." },
    { "prop_resp": "People trespassing or loitering." },
    { "prop_resp": "Trash out of place." },
    { "prop_resp": "Store Lighting out or broken." },
    { "prop_resp": "Other items that are not normal to the property." },
    {
      "prop_resp": `Door Check:  At the time where all employees have left the officer is expected to check every door of every store to ensure that the last employee locked up and has set all necessary alarms.
        Lighting, Trash and Security Assessments:  It is expected of officers during the patrols to provide the customer if applicable; lighting, trash and security assessments.  These consist of notifying the customers via reports of all necessary lights that are out, trash that is not disposed of properly and any potential areas that there could be a breach of security (no fence, hole in fence, overgrown trees or bushes to hide in etc.).
        `},
    { "prop_resp": `Reporting:  During every tour of the property it is imperative that the officer report information in detail of all that they saw during the patrol.  This is the means by which the customer has assurance that the property was patrolled on any given period of time.  Refer to the Operations Manual Activity reporting for details on how to write clear and accurate reports.` },



  ]

  job_collection = {
    "job":
    {
      "title": "Community Base Roving Patrol-Vehicle Patrol Duty",
      "location": "Austin Texas ",
      reporting: "Lead Officer/ Field Supervisor",
      summary: `An CTX Community Based Officer (CBS) is an officer that does Patrol Duty is performed in a CTX Patrol Service Security vehicle. 
        This vehicle is fully equipped with graphics, A GPS mapping device, a Laptop computer, a mobile phone and a light bar and go light.  
        The Vehicle Patrol Duty is a demanding and high priority position. The officers working should be thorough efficient and highly trained.  
        The officers must maintain the schedule and also address any security needs as they arise.  `,
      //  "responsiblities": this.responsibilities ,
      reqs: {
        age: "18 years of age or older" ,
        edu: "High school diploma or GED",
        background: "Pass background investigation",
        drug: "Pass drug test",
        driving: "Good driving record",
        training: "Complete training modules within first month",
        organization: "Excellent organization, oral, and written communication skills ",
        team: "Works collaboratively and supports efforts of team members",
        procedures: "Ability to conform to established procedures and to help identify and solve problems",
        improve: "Ability to get along with other employees, follow directions, work under stress, add value and continuously improve",
        service: "Demonstrates a commitment to service, organization values and professionalism through appropriate conduct and demeanor at all times",
        weight: "Must be able to move equipment weighing up to 50 pounds "
      }
    }
  };

  job_duty_1: any[] = [
    { "title": "Community Base Roving Patrol-Vehicle Patrol Duty" },
    { "location": "Austin Texas " },
    { "reporting": "Lead Officer/ Field Supervisor" },
    {
      "position_summary": `An CTX Community Based Officer (CBS) is an officer that does Patrol Duty is performed in a CTX Patrol Service Security vehicle. 
        This vehicle is fully equipped with graphics, A GPS mapping device, a Laptop computer, a mobile phone and a light bar and go light.  
        The Vehicle Patrol Duty is a demanding and high priority position. The officers working should be thorough efficient and highly trained.  
        The officers must maintain the schedule and also address any security needs as they arise.  `},
    { "responsiblities": this.responsibilities },
    { "reqs": "18 years of age or older" },
    { "reqs": "High school diploma or GED" },
    { "reqs": "Pass background investigation" },
    { "reqs": "Pass drug test" },
    { "reqs": "Good driving record" },
    { "reqs": "Complete training modules within first month" },
    { "reqs": "Excellent organization, oral, and written communication skills " },
    { "reqs": "Works collaboratively and supports efforts of team members" },
    { "reqs": "Ability to conform to established procedures and to help identify and solve problems" },
    { "reqs": "Ability to get along with other employees, follow directions, work under stress, add value and continuously improve" },
    { "reqs": "Demonstrates a commitment to service, organization values and professionalism through appropriate conduct and demeanor at all times" },
    { "reqs": "Must be able to move equipment weighing up to 50 pounds " },



  ]
  job_duty_2: any[] = [
    { "title": "Community Base Security Officer (CBS) " },
    { "location": "Austin Texas " },
    { "reporting": "Lead Officer" },
    { "responsiblities": "N/A" },
    {
      "position_summary": `An CTX Community Based Officer (CBS) is an officer that has completed all appropriate on-line and physical training modules, taken and passed a drug test and background check, and is ready for duty in a field or dress uniform as defined under Uniform in the CTX Officer Standard Operating Procedures. Basic duties include protecting a site in a fixed location.  
        All duties listed below are minimum requirements 
        additional duties listed in the site’s Post Orders are requirements as well.  `},
  ]

  constructor() {

  }
}

