
   <br>
   <br>
<div class="row justify-content-center">
        <div class="col-md-4 text-left info-card row-1 ">



                <h2>Virtual Surveillance</h2>
                <span>
                        We first assess your property or location, and create a custom site-plan showing our recommended
                        camera placement and their area coverage.
                        Then we install and implement the software and hardware needed to monitor your location.
                        Once implemented, you’re all set! Our monitoring team will be on watch and report all findings
                        in daily reports for you to review.
                </span>
                <br>
        </div>
        <div class="col-md-4 text-left">
                <h2>Special Events</h2>
                <span>
                        Our Dedicated/Event Officer will Patrol Specific Events or areas as requested per contract. Our
                        Officers will be well trained and dressed in CTX Patrol uniforms to help create a presence that
                        displays authority.onsite to
                        reduce criminal opportunity, burglary, prowlers, loitering, auto theft, vagrancy and vandalism.
                        Our Officers will be onsite during specific times of the event.
                </span>
        </div>
        
        <div class="col-md-4 text-left bottom-3">
                

                <h2>Seasonal Service</h2>
                <span>
                        CTX Patrol offers dedicated and patrol service for seasonal events and needs. Holidays,
                        Summertime and other times during the year when activity increases and there is a need to ensure
                        your property is safe and secure.
                        Our Officers will do reports that will be uploaded into your dedicated client portal for you to
                        review.
                </span>

        </div>
</div>
<br>

<div class="row justify-content-center ">
        <div class="col-md-4 text-left">
                <h2>Controlled Access</h2>
                <span>
                        Our Officer/ Officers will be onsite at entry points on the property. They will interact will
                        monitor these entry points to ensure that only authorized persons are gaining entry to your
                        property.
                        We follow all rules and guidelines set forth by the client as to who will have entry and how
                        that will occur.
                        The Officer will also escort guests/visitors on location if it is required. All Officers will be
                        trained for your specific needs and will perform all duties in a professional courteous manner.

                </span>
                <br>
        </div>
        <div class="col-md-4 text-left">
                <h2>Fire Watch</h2>
                <span>
                        Our Dedicated Officer will Patrol your property including buildings, hallways, parking areas and
                        any recreational facilities on foot to make sure there are no fires or signs of fires on
                        property.
                        After inspecting your property a report of our findings will be uploaded into your dedicated
                        client portal for you and to review. Our Officer will be onsite while you and your staff is not
                        to guarantee proper fire watch coverage is maintained.
                </span>

        </div>
        <div class="col-md-4 text-left">
                <h2>Security Consult</h2>
                <span>
                        A CTX Security Consultant will come out to your commercial or residential property and do a full
                        assessment of your property.
                        We will then review our findings with you and provide a detailed plan specific to your property.
                        This plan can include patrol service, dedicated service, video surveillance, Alarm response and
                        other services that would best fit your property.
                        <!-- Our plans can be flexible so they can consist of more than just 1 of these services but a
                        combination of several services to meet your needs.
                        Plans can also be flexible enough to change during your service time with CTX Patrol. -->
                </span>

                <span *ngIf="isShow_1">
                        Our plans can be flexible so they can consist of more than just 1 of these services but a
                        combination of several services to meet your needs.
                        Plans can also be flexible enough to change during your service time with CTX Patrol.
                </span>
                <div class="text-center">
                        <button *ngIf="!isShow_1" class="me-auto" (click) = toggleDisplay()>See More <i class='fa fa-angle-down' ></i></button>
                        <button *ngIf="isShow_1" class="me-auto" (click) = toggleDisplay()>See Less <i class='fa fa-angle-up' ></i></button>

                </div>

        </div>
</div>
<br>


<div class="row justify-content-center ">
        <div class="col-md-4 text-left">
                
                <h2>Dedicated Guard</h2>
                <span>
                        Our Dedicated Officer will Patrol the entire property including buildings, streets, pools,
                        hallways, parking areas and any recreational facilities by vehicle and on foot.
                        We inspect your property and report all findings in our nightly reports. Our Officers will be
                        well trained and dressed in CTX Patrol uniforms to help create a presence that displays
                        authority.onsite to
                        reduce criminal opportunity, burglary, prowlers, loitering, auto theft, vagrancy and vandalism.
                        Nightly reports will include suspicious activities, vandalism and maintenance issues (graffiti,
                        lights out, inoperable doors, locks, water leaks, property rules and code violations, etc.) and
                        provide digital photos.
<!--                         
                        Patrol Reports can be provided by email. Our Officers will enforce general laws and community
                        Rules & Regulations while onsite. -->
                </span>

                <span  *ngIf = "isShow_2">
                        Patrol Reports can be provided by email. Our Officers will enforce general laws and community
                        Rules & Regulations while onsite.
                </span>
                <br>
                <div class="text-center">
                        <button *ngIf="!isShow_2" class="me-auto" (click) = toggleDisplay2()>See More <i class='fa fa-angle-down' style='border: none;'></i></button>
                        <button *ngIf="isShow_2" class="me-auto" (click) = toggleDisplay2()>See Less <i class='fa fa-angle-up' style='border: none;'></i></button>

                </div>

                <br>
        </div>
        <div class="col-md-4">
                <h2>Alarm Response</h2>
                <span>
                        CTX Patrol Service Alarm Response provides our commercial and residential clients with alarm
                        response coverage to verify alarm activity at any client site or location.
                        Local law enforcement agencies prefer this verification to ensure that an actual crime is
                        occurring or has occurred before they will respond to an alarm.
                        Our network facilitates a quick and professional response to assess a situation. CTX Patrol
                        Service's professionally trained Officers are supported by our Dispatch Team, which is staffed
                        24/7. Our Dispatch Officer will dispatch a Patrol Officer to ascertain the cause of the alarm at a
                        client site and verify if a break-in or crime has occurred.


                        <!-- Once verified, the officer will notify the Dispatcher, which can then contact law enforcement
                        and client-designated officials with a verified alarm response, as well as relay further
                        instructions to the patrol officer on site.
                        All findings will be documented in our reporting system for our client to review. -->
                </span> 
                <span *ngIf = "isShow_3">
                        Once verified, the officer will notify the Dispatcher, which can then contact law enforcement
                        and client-designated officials with a verified alarm response, as well as relay further
                        instructions to the patrol officer on site.
                        All findings will be documented in our reporting system for our client to review.
                </span>
                <br>
                <div class="text-center">
                        <button *ngIf="!isShow_3" class="me-auto" (click) = toggleDisplay3()>See More <i class='fa fa-angle-down' style='border: none;'></i></button>
                        <button *ngIf="isShow_3" class="me-auto" (click) = toggleDisplay3()>See Less <i class='fa fa-angle-up' style='border: none;'></i></button>

                </div>
        
        
       
                        <!-- <textarea class="text-align-left" disabled rows="14" cols="50">CTX Patrol Service Alarm Response provides our commercial and residential clients with alarm response coverage to verify alarm activity at any client site or location. Local law enforcement agencies prefer this verification to ensure that an actual crime is occurring or has occurred before they will respond to an alarm. Our network facilitates a quick and professional response to assess a situation. CTX Patrol Service's professionally trained Officers are supported by our Dispatch Team, which is staffed 24/7. Our Dispatch Officer will dispatch a Patrol Officer to ascertain the cause of the alarm at a client site and verify if a break-in or crime has occurred. Once verified, the officer will notify the Dispatcher, which can then contact law enforcement and client-designated officials with a verified alarm response, as well as relay further instructions to the patrol officer on site. All findings will be documented in our reporting system for our client to review.      
                        </textarea> -->

                


        </div>
        <div class="col-md-4 text-left">
                <h2>Patrol Services</h2>
                <span>
                        With CTX Patrol Service LLC. Premium Patrol Service, our uniformed officer arrives on property
                        and conducts a thorough inspection of your property.
                        This is not the typical security truck drive-through. We have verified services, and our Patrols
                        may be random or scheduled for a specific time. The number of visits and inspections per shift
                        are customized to the client’s needs. Our Patrol Officer will Patrol the entire property
                        including Checking buildings, pools, parking areas and any recreational facilities by vehicle.
                        After each patrol of the property, we report all findings in our reports.
                </span>
                
        </div>
</div>
<br>